import Iconify from "../Iconify";
import { useEffect, useState } from "react";
import MenuPopover from "../MenuPopover";
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { Box, Button, Checkbox, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import uuidv4 from "src/utils/uuidv4";

type Props = {
  headerColumn: any;
  options?: any[];
  orderBy?: string;
  onSort: (id: string) => any;
  setOrder?: React.Dispatch<React.SetStateAction<"asc" | "desc">>;
};
  
export default function TableModalSort({
  headerColumn,
  options,
  orderBy,
  onSort,
  setOrder
}: Props) {

  const generateInitialState = () => {
    const initialState = {};
    options?.forEach(option => {
      if (option.headerColumn === headerColumn?.id) {
        initialState[option.value] = 'asc';
      }
    });
    return initialState;
  };

  const [open, setOpen] = useState<HTMLButtonElement | null>(null);

  const [sortBy, setSortBy] = useState('');

  const [switchStates, setSwitchStates] = useState(generateInitialState);

  const handleOpen = (currentTarget: HTMLButtonElement) => {
    setOpen(currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeSortBy = (value: any) => {
    if (sortBy === value) {
      setSortBy(''); 
      setSwitchStates(generateInitialState());
    } else {
      setSortBy(value);
    }
    onSort(sortBy === value ? 'id' : value);
  };

  const handleSwitchChange = (value) => (event) => {
    const newOrder = event.target.checked ? 'desc' : 'asc';
    setSwitchStates({[value]: newOrder});
    setOrder?.(newOrder);
  };

  useEffect(() => {
    if (orderBy && sortBy !== orderBy) {
      setSwitchStates(generateInitialState());
      setSortBy(orderBy);
    }
  }, [orderBy]);
  
  return(
    <>
      <Button
        color="inherit"
        disableFocusRipple
        disableRipple
        onClick={(event) => handleOpen(event.currentTarget)}
        endIcon={<ExpandCircleDownOutlinedIcon />}
        sx={{
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
        }}
      >
        {headerColumn?.label}
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        disabledArrow={true}
      >
        {options?.filter((e) => e.headerColumn === headerColumn?.id).map((option) => {
          
          const { value, icon, label } = option;
          const isChecked = switchStates[value] === 'desc';
          
          return (
            <Box sx={{ flexGrow: 1 }} key={uuidv4()}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle1">
                  {icon}
                  {label}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<Iconify icon="material-symbols:circle-outline" color="#637381" />}
                      checkedIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
                      checked={sortBy === value}
                      onChange={() => handleChangeSortBy(value)}
                    />
                  }
                  label={null}
                  sx={{ marginLeft: 'auto', marginRight: 0 }}
                />
              </Stack>
              <Stack direction="row" alignItems="center" justifyContent="center">
                <Typography variant="caption">
                  ASC
                </Typography>
                
                <Switch
                  disabled={value !== sortBy}
                  checked={isChecked}
                  onChange={handleSwitchChange(value)}
                />
                <Typography variant="caption">
                  DESC
                </Typography>
              </Stack>
            </Box>
          )
        })}
      </MenuPopover>
    </>
  )
}