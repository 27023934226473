import { 
  Backdrop,
  Button, 
  Card, 
  CircularProgress, 
  Dialog, 
  DialogTitle, 
  Divider, 
  Drawer, 
  FormHelperText, 
  Grid, 
  IconButton, 
  List, 
  ListItem, 
  MenuItem, 
  Stack, 
  TextField, 
  Typography 
} from "@mui/material";
import * as Yup from 'yup';
import moment from "moment";
import produce from 'immer';
import { cloneDeep, isEmpty } from "lodash";
import InputMask from 'react-input-mask';
import { capitalCase } from "change-case";
import { MobileDatePicker } from "@mui/lab";
import { useEffect, useMemo, useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Add, Check, Edit } from "@mui/icons-material";
import { getFhirIdFromEntity, phoneFormat } from "src/utils/fhir";
import { useOrganizations, useValueSet } from "src/@nicheaim/fhir-react";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { ValueSetWrapper } from "src/@nicheaim/fhir-base/wrappers/ValueSet";
import { convertValueToValueSet } from "src/sections/crs/common/common-utils";
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { OrganizationWrapper } from "src/@nicheaim/fhir-base/wrappers/Organization";
import AddAddress from "src/sections/crs/referral/components/workflow-step/AddressChecklistItem/AddAddress";
import { ContactPointUse, PatientGender } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

interface ConfirmMemberInfoProps {
  patient: WrappedPatient | null;
  isOpen: boolean;
  handlerIsOpen: Function;
  handlePatient: (data: any) => Promise<any>;
  handleConfirmMember: () => Promise<any>;
  refreshPatient: () => Promise<any>;
}

export default function ConfirmMemberInfo({
  patient,
  isOpen,
  handlerIsOpen,
  handlePatient,
  handleConfirmMember,
  refreshPatient,
}: ConfirmMemberInfoProps) {

  const [firstNameEditMode, setFirstNameEditMode] = useState(false);
  const [middleNameEditMode, setMiddleNameEditMode] = useState(false);
  const [secondNameEditMode, setSecondNameEditMode] = useState(false);
  const [dobEditMode, setDobEditMode] = useState(false);
  const [genderEditMode, setGenderEditMode] = useState(false);
  const [emailEditMode, setEmailEditMode] = useState(false);
  const [phoneEditMode, setPhoneEditMode] = useState(false);
  const [languageEditMode, setLanguageEditMode] = useState(false);
  const [addressEditMode, setAddressEditMode] = useState(false);
  const [organizationEditMode, setOrganizationEditMode] = useState(false);
  const [patientInfoLoaded, setPatientInfoLoaded] = useState(false);
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [address, setAddress] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  
  const [ organizations, { isFetching: isOrganizationLoading } ] = useOrganizations({ map: OrganizationWrapper });
  const [ simpleLanguage, ] = useValueSet('simple-language', { map: ValueSetWrapper });

  useEffect(() => {
    setPatientInfoLoaded(false);
    if (!isOpen) {
      setFirstNameEditMode(false);
      setMiddleNameEditMode(false);
      setSecondNameEditMode(false);
      setDobEditMode(false);
      setGenderEditMode(false);
      setEmailEditMode(false);
      setPhoneEditMode(false);
      setLanguageEditMode(false);
      setAddressEditMode(false);
      setOrganizationEditMode(false);
    }
    setPatientInfoLoaded(true);
  }, [isOpen]);


  useEffect(() => {
    if(isOpen){
      reset(defaultValues)
    }
  },[isOpen]);

  const saveChanges = async (data: any) => {
    setValue('address_line', data.line[0]);
    setValue('address_line_2', data.line[1]);
    setValue('address_city', data.city);
    setValue('address_district', data.district);
    setValue('address_postal', data.postalCode);
    setValue('address_state', data.state);
    setValue('address_use', data.use);
    clearErrors(['address_line','address_city','address_postal','address_state'])
    setAddress(data);
    setOpenAddAddress(false);
  };

  const schema = Yup.object().shape({
    given: Yup.string()
      .required("First Name is required")
      .matches(/^[a-zA-Z\s]+$/, 'First Name cannot contain numbers'),
    middle_name: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(/^[a-zA-Z\s]+$/, 'Middle Name cannot contain numbers'),
    family: Yup.string()
      .required("Last Name is required")
      .matches(/^[a-zA-Z\s-]+$/, 'Last Name cannot contain numbers'),
    birthdate: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Date of birth is required')
    .test('birthdate', 'Date of birth cannot be a date in the future', (value) => {
      const birthDate = moment(value ?? null);
      if (birthDate?.isAfter(moment(), 'day')) {
        setError('birthdate', {
          message: 'Date of birth cannot be a date in the future',
          type: 'manual',
        });
        return false;
      }
      return true;
    }),
    gender: Yup.string().required("Gender is required"),
    emailType:Yup.string().when('emailAddress', (email, schema) => {
      if (email) return schema.required('Email Type is required');
    }),
    emailAddress: Yup.string()
      .email("Email must be a valid email address"),
    phoneType: Yup.string().when('phoneNumber', (phone, schema) => {
      if (phone) return schema.required('Phone Type is required');
    }),
    address_line: Yup.string().required("Street is required"),
    address_line_2: Yup.string(),
    address_city: Yup.string().required("City is required"),
    address_state: Yup.string().required("State is required"),
    address_postal: Yup.string().required("Zip Code is required"),
  });

  const defaultValues = useMemo(
    () =>
    ({
      given: patient?.name?.[0].given?.[0] || '',
      middle_name: patient?.name?.[0]?.given?.[1] || '',
      family: patient?.name?.[0].family,
      birthdate: patient?.birthDate ?
        moment.utc(new Date(patient?.birthDate)).format('MM/DD/YYYY') :
        '',
      gender: patient?.gender,
      emailType: patient?.getPrimaryEmail()?.use ?? '',
      emailAddress: patient?.getPrimaryEmail()?.value ?? '',
      phoneType: patient?.getPrimaryPhone()?.use ?? '',
      phoneNumber: patient?.getPrimaryPhone()?.value ?? '',
      language: patient?.getLanguage()?.[0]?.value ?? '',
      address_use: patient?.getPrimaryAddress()?.use ?? '',
      address_line: patient?.getPrimaryAddress()?.line?.[0] ?? '',
      address_line_2: patient?.getPrimaryAddress()?.line?.[1] ?? '',
      address_city: patient?.getPrimaryAddress()?.city ?? '',
      address_state: patient?.getPrimaryAddress()?.state ?? '',
      address_postal: patient?.getPrimaryAddress()?.postalCode ?? '',
      address_district: patient?.getPrimaryAddress()?.district || '',
      organization: patient?.managingOrganization?.reference ? 
        getFhirIdFromEntity(patient?.managingOrganization?.reference) : '',
      organizationName: patient?.managingOrganization?.display ?? '',
  }),[patient]
  );

  const methods = useForm({ resolver: yupResolver(schema), defaultValues });

  const {
    control,
    watch,
    reset,
    setError,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = methods;

  const values = watch();

  const handlerStep = async () => {
    await handleConfirmMember();
    await refreshPatient();
  };

  const onSubmit = async (data: any) => {

    setOpenBackdrop(true);

    try {
      
      const coding = convertValueToValueSet(data.language || '', simpleLanguage);
      const codeValueLanguage: any = {
        language: { coding: [coding], text: coding?.display },
        preferred: true
      };

      let getOrganization;
      if(!isOrganizationLoading && organizations?.length > 0 && data?.organization) 
        getOrganization = organizations?.find((item) => item?.id === data?.organization);
      
      const emailIndex = patient?.telecom?.findIndex(item => item.system === "email");
      const phoneIndex = patient?.telecom?.findIndex(item => item.system === "phone");

      let patientUpdate: any = cloneDeep(patient ?? {});

      if (!patientUpdate.telecom) {
        patientUpdate.telecom = [];
      }

      if(!patientUpdate.communication){
        patientUpdate.communication = [];
      }
      
      const emailUpdate = {
        system: 'email' as any,
        use: data.emailType as ContactPointUse,
        value: data.emailAddress,
      };

      const phoneUpdate = {
        system: 'phone' as any,
        use: data.phoneType as ContactPointUse,
        ...(data.areaCode
          ? { value: `${data.areaCode} ${data.phoneNumber}` }
          : { value: `${data.phoneNumber}` })
      };
      
      if (emailIndex !== undefined && emailIndex !== -1){
        patientUpdate.telecom[emailIndex] = emailUpdate;
      } else if(emailUpdate?.value){
        patientUpdate.telecom.push(emailUpdate);
      }

      if(phoneIndex !== undefined && phoneIndex !== -1){
        patientUpdate.telecom[phoneIndex] = phoneUpdate;
      }else if(phoneUpdate?.value){
        patientUpdate.telecom.push(phoneUpdate);
      }

      if(patientUpdate.communication.length > 0 && coding?.code){
        patientUpdate.communication[0] = codeValueLanguage;
      }else if(coding?.code){
        patientUpdate.communication.push(codeValueLanguage);
      }

      await handlePatient(
        produce(patientUpdate!, (draft) => {
          if (!draft.name) draft.name = [];
          if (!draft.name[0]) draft.name[0] = {};
          draft.name[0].family = data.family;
          if (!draft.name[0].given) draft.name[0].given = [];
          draft.name[0].given[0] = data.given;
          draft.name[0].given[1] = data.middle_name;
          draft.birthDate = moment.utc(new Date(data.birthdate)).format('YYYY-MM-DD');
          draft.gender = data.gender as PatientGender;
          if (!draft.address) draft.address = [];
          if (!isEmpty(address)) draft.address[0] = address;
          draft.managingOrganization ??= {};
          if(getOrganization?.id) {  
            draft.managingOrganization.reference = `Organization/${getOrganization?.id}`;
            draft.managingOrganization.display = getOrganization?.name;
          }
        })
      );

      handlerStep();

    } catch (error) {
      console.log('error',error)
    }
    handlerIsOpen(false);  
    setOpenBackdrop(false);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => {
          handlerIsOpen(false);
        }}
      >
        {patientInfoLoaded && patient !== null ? (
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <List component="nav">
              <ListItem>
                <Grid container>
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isOrganizationLoading}
                    >
                      Confirm Information
                    </Button>
                    <Button
                      onClick={() => {
                        handlerIsOpen(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Grid>
              </ListItem>
              <Divider />
              <ListItem>
                <Grid
                  container
                  direction="column"
                  marginTop={2}
                  width={800}
                >
                  <Grid container direction="row" alignItems="center">
                    <Grid container item xs={3}>
                      <Typography>First Name*</Typography>
                    </Grid>
                    <Grid container item xs={8}>
                      {firstNameEditMode ? (
                        <RHFTextField name="given" label="First Name*" disabled={!firstNameEditMode}/>
                      ) : (
                        <Stack>
                          <Typography>
                            <b>{values.given}</b>
                          </Typography>

                          {!!errors.given && 
                            <FormHelperText error> {errors.given?.message}</FormHelperText>}
                        </Stack>
                      )}
                    </Grid>

                    <Grid container item xs={1}>
                      <IconButton
                        onClick={() =>
                          setFirstNameEditMode((prev) => !prev)
                        }
                      >
                        {firstNameEditMode ? <Check /> : <Edit />}
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid container item xs={3}>
                      <Typography>Middle Name</Typography>
                    </Grid>

                    <Grid container item xs={8}>
                      {middleNameEditMode ? (
                        <RHFTextField name="middle_name" label="Middle Name" />
                      ) : (
                        <Typography>
                          <b>{values.middle_name}</b>
                        </Typography>
                      )}
                    </Grid>

                    <Grid container item xs={1}>
                      <IconButton
                        onClick={() =>
                          setMiddleNameEditMode((prev) => !prev)
                        }
                      >
                        {middleNameEditMode ? <Check /> : <Edit />}
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid container item xs={3}>
                      <Typography>Last Name*</Typography>
                    </Grid>

                    <Grid container item xs={8}>
                      {secondNameEditMode ? (
                        <RHFTextField name="family" label="Last Name*" />
                      ) : (
                        <Stack>
                          <Typography>
                            <b>{values.family}</b>
                          </Typography>

                          {!!errors.family && 
                            <FormHelperText error> {errors.family?.message}</FormHelperText>}
                        </Stack>
                      )}
                    </Grid>

                    <Grid container item xs={1}>
                      <IconButton
                        onClick={() =>
                          setSecondNameEditMode((prev) => !prev)
                        }
                      >
                        {secondNameEditMode ? <Check /> : <Edit />}
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid container item xs={3}>
                      <Typography>Date of Birth*</Typography>
                    </Grid>

                    <Grid container item xs={8}>
                      {dobEditMode ? (
                        <Controller
                          name="birthdate"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <MobileDatePicker
                              label="Date of Birth*"
                              value={field.value || null}
                              onChange={(newValue) => {
                                field.onChange(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  error={!!error}
                                  helperText={error?.message}
                                />
                              )}
                            />
                          )}
                        />
                      ) : (
                        <Stack>
                          <Typography>
                            <b>{values.birthdate && moment.utc(values.birthdate).format('l')}</b>
                          </Typography>

                          {!!errors.birthdate && 
                            <FormHelperText error> {errors.birthdate?.message}</FormHelperText>}
                        </Stack>
                      )}
                    </Grid>

                    <Grid container item xs={1}>
                      <IconButton
                        onClick={() => setDobEditMode((prev) => !prev)}
                      >
                        {dobEditMode ? <Check /> : <Edit />}
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid container item xs={3}>
                      <Typography>Gender*</Typography>
                    </Grid>

                    <Grid container item xs={8}>
                      {genderEditMode ? (
                        <RHFSelect name="gender" label="Gender*" fullWidth={true}>
                          <MenuItem disabled />
                          <MenuItem value="female">Female</MenuItem>
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="other">Other</MenuItem>
                          <MenuItem value="unknown">Unknown</MenuItem>
                        </RHFSelect>  
                      ) : (
                        <Stack>
                          <Typography>
                            <b>{capitalCase(values.gender || '')}</b>
                          </Typography>

                          {!!errors.gender && 
                            <FormHelperText error> {errors.gender?.message}</FormHelperText>}
                        </Stack>
                      )}
                    </Grid>

                    <Grid container item xs={1}>
                      <IconButton
                        onClick={() => setGenderEditMode((prev) => !prev)}
                      >
                        {genderEditMode ? <Check /> : <Edit />}
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid container item xs={3}>
                      <Typography>Email</Typography>
                    </Grid>

                    <Grid container item xs={8}>
                      {emailEditMode ? (
                        <>
                          <Grid xs={3}>
                            <RHFSelect name="emailType" label="Email Type" fullWidth={true}>
                              <MenuItem disabled />
                              <MenuItem value="home">Home</MenuItem>
                              <MenuItem value="work">Work</MenuItem>
                            </RHFSelect>
                          </Grid>

                          <Grid xs={9}>
                            <RHFTextField name="emailAddress" label="Email" fullWidth />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid xs={3}>
                            <Typography>
                              <b>{capitalCase(values.emailType)}</b>
                            </Typography>
                          </Grid>

                          <Grid xs={9}>
                            <Typography>
                              <b>{values.emailAddress}</b>
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Grid container item xs={1}>
                      <IconButton
                        onClick={() => setEmailEditMode((prev) => !prev)}
                      >
                        {emailEditMode ? <Check /> : <Edit />}
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid container item xs={3}>
                      <Typography>Phone</Typography>
                    </Grid>

                    <Grid container item xs={8}>
                      {phoneEditMode ? (
                        <>
                          <Grid xs={3}>
                            <RHFSelect name="phoneType" label="Phone Type" fullWidth={true}>
                              <MenuItem disabled />
                              <MenuItem value="home">Home</MenuItem>
                              <MenuItem value="work">Work</MenuItem>
                              <MenuItem value="mobile">Mobile</MenuItem>
                            </RHFSelect>
                          </Grid>
                          <Grid xs={9}>
                            <Controller
                              name="phoneNumber"
                              control={control}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <InputMask mask="999-999-9999" value={value} onChange={onChange}>
                                  {(inputProps: any) => (
                                    <TextField
                                      label="Phone Number"
                                      {...inputProps}
                                      error={!!error?.message}
                                      helperText={error?.message}
                                    />
                                  )}
                                </InputMask>
                              )}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid xs={3}>
                            <Typography>
                              <b>{capitalCase(values.phoneType)}</b>
                            </Typography>
                          </Grid>

                          <Grid xs={9}>
                            <Typography>
                              <b>{values.phoneNumber === "null" ? '' : phoneFormat(values.phoneNumber)}</b>
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Grid container item xs={1}>
                      <IconButton
                        onClick={() => setPhoneEditMode((prev) => !prev)}
                      >
                        {phoneEditMode ? <Check /> : <Edit />}
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid container item xs={3}>
                      <Typography>Language</Typography>
                    </Grid>

                    <Grid container item xs={8}>
                      {languageEditMode ? (
                        <RHFSelect name="language" label="Language" fullWidth={true}>
                          <MenuItem disabled />
                          {simpleLanguage?.asList().map((option) => (
                            <MenuItem key={option.code} value={option.display}>
                              {option.display}
                            </MenuItem>
                          ))}
                        </RHFSelect>
                      ) : (
                        <Stack>
                          <Typography>
                            <b>{values.language}</b>
                          </Typography>
                        </Stack>
                      )}
                    </Grid>

                    <Grid container item xs={1}>
                      <IconButton
                        onClick={() => setLanguageEditMode((prev) => !prev)}
                      >
                        {languageEditMode ? <Check /> : <Edit />}
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid container item xs={3}>
                      <Typography>Address*</Typography>
                    </Grid>

                    <Grid container item xs={8}>
                      {addressEditMode ? (
                        <>
                          <Grid xs={12} marginTop={1} display="flex" justifyContent="flex-end">
                            <Typography
                              style={{ display: 'flex', justifyContent: 'space-between' }}
                              variant="button"
                              sx={{ textTransform: 'uppercase' }}
                            >
                              <Button
                                color="primary"
                                startIcon={<Add />}
                                onClick={() => setOpenAddAddress(true)}
                              >
                                Add
                              </Button>
                            </Typography>
                          </Grid>
                          <Grid xs={6} marginTop={1}>
                            <RHFTextField name="address_line" label="Street*" disabled/>
                          </Grid>
                          <Grid xs={6} marginTop={1}>
                            <RHFTextField name="address_line_2" label="Street 2" disabled/>
                          </Grid>
                          <Grid xs={6} marginTop={1}>
                            <RHFTextField name="address_city" label="City*" disabled/>
                          </Grid>
                          <Grid xs={6} marginTop={1}>
                            <RHFTextField name="address_state" label="State*" disabled/>
                          </Grid>
                          <Grid xs={6} marginTop={1}>
                            <RHFTextField name="address_district" label="County" disabled/>
                          </Grid>
                          <Grid xs={6} marginTop={1}>
                            <RHFTextField name="address_postal" label="Zip Code*" disabled/>
                          </Grid>
                        </>
                      ) : (
                          <Grid xs={9}>
                            <Typography>
                              <b>{
                                `${values.address_line || ''}, ${values.address_line_2 || ''} ${values.address_city || ''}, 
                                ${values.address_state || ''}, ${values.address_district || ''},  ${values.address_postal || ''}`
                              }</b>
                            </Typography>
                            <Stack>
                              {!!errors.address_line && 
                                <FormHelperText error> {errors.address_line?.message}</FormHelperText>}

                              {!!errors.address_city && 
                                <FormHelperText error> {errors.address_city?.message}</FormHelperText>}
                              
                              {!!errors.address_state && 
                                <FormHelperText error> {errors.address_state?.message}</FormHelperText>}
                              
                              {!!errors.address_postal && 
                                <FormHelperText error> {errors.address_postal?.message}</FormHelperText>}
                            </Stack>
                          </Grid>
                      )}
                    </Grid>

                    <Grid container item xs={1}>
                      <IconButton
                        onClick={() => setAddressEditMode((prev) => !prev)}
                      >
                        {addressEditMode ? <Check /> : <Edit />}
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid container item xs={3}>
                      <Typography>Organization</Typography>
                    </Grid>

                    <Grid container item xs={8}>
                      {organizationEditMode ? (
                        <RHFSelect name="organization" label="Organization" fullWidth={true}>
                        {organizations?.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </RHFSelect> 
                      ) : (
                        <Typography>
                          <b>{values.organizationName}</b>
                        </Typography>
                      )}
                    </Grid>

                    <Grid container item xs={1}>
                      <IconButton
                        onClick={() =>
                          setOrganizationEditMode((prev) => !prev)
                        }
                      >
                        {organizationEditMode ? <Check /> : <Edit />}
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </FormProvider>
        ) : (
          <Stack width={500} alignItems="center" marginTop={50}>
            <CircularProgress />
          </Stack>
        )}
      </Drawer>
      <Dialog open={openAddAddress} fullWidth maxWidth="md">
        <DialogTitle> Add Address</DialogTitle>
        <Card sx={{ p: 2, overflowY: 'scroll' }}>
          <AddAddress 
            isEditable={true}
            externalAddress={{
              line: patient?.getPrimaryAddress()?.line?.[0] || '',
              city: patient?.getPrimaryAddress()?.city || '',
              district: patient?.getPrimaryAddress()?.city || '',
              state: patient?.getPrimaryAddress()?.state || '',
              postalCode: patient?.getPrimaryAddress()?.postalCode || '',
              country: patient?.getPrimaryAddress()?.country || '', 
            }}
            handleClose={() => setOpenAddAddress(false)} 
            handleSave={saveChanges}
          />
        </Card>
      </Dialog>
    </>
  );
}