import {
  Card,
  Container,
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  debounce,
} from '@mui/material';
import { CaseRow } from '.';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import agent from 'src/api/agent';
import Page from 'src/components/Page';
import { TableToolbar } from '../common';
import { useCallback, useEffect, useState } from 'react';
import useTable from '../../../hooks/useTable';
import useSettings from 'src/hooks/useSettings';
import { caseService } from 'src/crs/case/service';
import { CaseManager } from 'src/@types/crs/referral';
import { TableHeadCustom } from 'src/components/table';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { PaginateQuery } from 'src/api/pagination/dtos';
import { TABLE_HEAD_CASES_LIST } from '../common/table-head';
import { PaginatedCaseDto } from 'src/crs/case/service/CaseService';
import FilterDrawer from 'src/components/FilterDrawer';
import { Tooltip } from '@mui/material';
import { TextField } from '@mui/material';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { caseDtoToCaseManager } from '../common/common-utils';
import NoResultsTableRow from '../common/NoResultsTableRow';
import LoadingTableRow from '../../../components/table/LoadingTableRow';
import { wait } from 'src/utils/timers';
import { translateTableHead } from '../helpers/common';
import useLocales from 'src/hooks/useLocales';
import useNotiMessage from 'src/hooks/useNotiMessage';

export default function CaseList() {
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage, setPage } = useTable({
    defaultRowsPerPage: 5,
    defaultOrderBy: 'id',
    initialIndex: 1,
    defaultCurrentPage: 1,
  });

  const [assignedTo, setAssignedTo] = useState<any>([]);
  const { themeStretch } = useSettings();
  const [isOpenAplyFilters, setIsOpenAplyFilters] = useState<boolean>(false);

  const [tableData, setTableData] = useState<PaginatedCaseDto | undefined | null>(null);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const { i18n } = useLocales();
  const { showErrorMessage } = useNotiMessage();
  const getPaginateQuery = (page: number, rowsPerPage: number, search: string, assignedTo: any) => {
    const paginateQuery: PaginateQuery = { page: page, limit: rowsPerPage };
    if (search) {
      paginateQuery.search = search;
    }
    paginateQuery.filter = {};

    if (assignedTo.length) {
      paginateQuery.filter.workflowOwnedBy = `$in:${assignedTo.join(',')}`;
    }
    return paginateQuery;
  };

  const fetchCaseList = async (
    page: number,
    rowsPerPage: number,
    search: string,
    assignedTo: any
  ) => {
    setIsLoading(true);
    await wait();
    const cases = await caseService.getAll(getPaginateQuery(page, rowsPerPage, search, assignedTo));
    setIsLoading(false);
    if (!cases) return showErrorMessage(i18n('error.case.list'));
    setTableData(cases);
  };

  const getCaseList = useCallback(debounce(fetchCaseList, 600), []);

  useEffect(() => {
    getCaseList(page, rowsPerPage, search, assignedTo);
    getAllUsers();
  }, [page, rowsPerPage, search]);

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(event, newPage + 1);
  };
  const getAllUsers = async () => {
    const result = await agent.User.getAllUsers();
    console.log(result);
    setAllUsers(result);
  };
  const handleSearch = (query: string) => {
    setSearch(query);
    setPage(1);
  };

  const dataFormatted = (): CaseManager[] => {
    const data = tableData?.data ? tableData.data.map((r) => caseDtoToCaseManager(r)) : [];
    return data;
  };

  const rows = dataFormatted();

  return (
    <Page title={i18n('case.list.title', 'crs')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          title={i18n('case.list.title', 'crs')}
          heading=""
          links={[
            { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
            { name: `${i18n('breadcrumbs.title', 'crs')}`},
            { name: `${i18n('case.list.title', 'crs')}`, href: PATH_DASHBOARD.crs.case },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Stack direction="row" sx={{ py: 2, px: 2 }}>
                <Grid xs={6}>
                  <TableToolbar filterMrn={search} onFilterMrn={handleSearch} />
                </Grid>
                <Grid container alignItems="center" justifyContent="flex-end" xs={6}>
                  <Tooltip title="Filter">
                    {assignedTo.length > 0 ? (
                      <IconButton
                        onClick={() => {
                          setIsOpenAplyFilters(true);
                        }}
                        sx={{ height: '40px', backgroundColor: '#7ac25b' }}
                      >
                        <FilterListIcon htmlColor="#087736" />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          setIsOpenAplyFilters(true);
                        }}
                        sx={{ height: '40px' }}
                      >
                        <FilterListIcon htmlColor="#8f9993" />
                      </IconButton>
                    )}
                  </Tooltip>
                </Grid>
              </Stack>
              <TableContainer>
                <Table>
                  <TableHeadCustom headLabel={translateTableHead(TABLE_HEAD_CASES_LIST, 'crs')} />
                  <TableBody>
                    {!isLoading ? (
                      rows?.length ? (
                        rows.map((row) => <CaseRow key={row.id} row={row} />)
                      ) : (
                        <NoResultsTableRow
                          colSpan={TABLE_HEAD_CASES_LIST.length}
                          text={i18n('case.list.listNotFound', 'crs')}
                        />
                      )
                    ) : (
                      <LoadingTableRow colSpan={TABLE_HEAD_CASES_LIST.length} />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid xs={12}>
                <Stack justifyContent="flex-end">
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={tableData ? tableData.meta.totalItems : 0}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    sx={{ borderTop: 0 }}
                  />
                </Stack>
              </Grid>
            </Card>
          </Grid>
          <FilterDrawer
            onApplyButtonClick={() => {
              getCaseList(page, rowsPerPage, search, assignedTo);
              setIsOpenAplyFilters(false);
            }}
            title={i18n('case.list.filterPopUp.title', 'crs')}
            onCloseIconButtonClick={() => {
              setIsOpenAplyFilters(false);
            }}
            onClearAllButtonClick={() => {
              setAssignedTo(null);
            }}
            anchor={'right'}
            open={isOpenAplyFilters}
          >
            <TaskFilterList
              setAssignedTo={setAssignedTo}
              assignedTo={assignedTo}
              setAllUsers={setAllUsers}
              users={allUsers}
              i18n={i18n}
            />
          </FilterDrawer>
        </Grid>
      </Container>
    </Page>
  );
}
interface TaskFilterListProps {
  setAssignedTo: any;
  assignedTo: any;
  setAllUsers: any;
  users: any;
  i18n?: any;
}
const TaskFilterList = ({
  setAssignedTo,
  assignedTo,
  setAllUsers,
  users,
  i18n,
}: TaskFilterListProps) => (
  <Box py={3}>
    <Box>
      <Grid item sx={{ marginTop: 1 }} xs={6}>
        <Typography fontSize={'1rem'} fontWeight={'bold'} marginBottom={1.4}>
          {i18n('case.list.filterPopUp.assignedTo', 'crs')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2} sx={{ p: 2 }}>
          <Autocomplete
            value={assignedTo}
            multiple
            fullWidth
            onChange={(_: React.SyntheticEvent, taskStatus) => {
              console.log(assignedTo);
              setAssignedTo([...taskStatus]);
            }}
            options={users?.map((option: any) => option?.email)}
            getOptionLabel={(option: string) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label={i18n('case.list.filterPopUp.assignedTo', 'crs')}
                variant="outlined"
              />
            )}
          />
        </Stack>
      </Grid>
    </Box>
  </Box>
);
