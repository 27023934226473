import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Container, Grid, IconButton, Typography } from '@mui/material';
import produce from 'immer';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Communication } from 'src/@nicheaim/fhir-base/mappings/Communication';
import { ServiceRequest } from 'src/@nicheaim/fhir-base/mappings/ServiceRequest';
import { CommunicationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Communication';
import { DocumentReferenceWrapper } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ServiceRequestWrapper } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import {
  useCommunications,
  useDocumentReferences,
  useImmunizations,
  usePatient,
  useServiceRequest,
  useValueSet,
} from 'src/@nicheaim/fhir-react';
import { PaginateQuery } from 'src/api/pagination/dtos';
import ExpandableCard from 'src/components/common/ExpandableCard';
import ComplementaryInfo from 'src/components/complementary-info/ComplementaryInfo';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import ButtonType from 'src/components/workflow/common/components/Button';
import CustomSelectType from 'src/components/workflow/common/components/CustomSelect';
import LinkType from 'src/components/workflow/common/components/Link';
import LinkModalType from 'src/components/workflow/common/components/LinkModal';
import { caseService } from 'src/crs/case/service';
import { CaseIntent, CaseStatus } from 'src/crs/dto/case/case.dto';
import { referralService } from 'src/crs/referral/services';
import {
  AssessmentsFormsResponse,
  ReferralContactAttemptResponse,
} from 'src/crs/referral/services/ReferralService';
import useHealthCareServicesByServiceRequests from 'src/hooks/useHealthCareServicesByServiceRequests';
import useLocales from 'src/hooks/useLocales';
import useSettings from 'src/hooks/useSettings';
import {
  Annotation,
  Identifier,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { PatientSummary } from 'src/sections/crs/patient';
import {
  ConsentForm,
  ContactForm,
  ImmunizationAddForm,
  InsuranceInfo,
  MissingNotes,
  ReferralHistory,
  ReferralSummary,
} from 'src/sections/crs/referral/';
import AddressInCountyDialog from 'src/sections/crs/referral/components/workflow-step/AddressChecklistItem/AddressInCountyDialog';
import FixAddressDialog from 'src/sections/crs/referral/components/workflow-step/AddressChecklistItem/FixAddressDialog';
import { createProgramsByServiceRequest } from 'src/services/api/referrals';
import { getRegistries } from 'src/services/api/registry';
import { useDialogStore } from 'src/stores/dialog';
import axiosInstance from 'src/utils/axios';
import { getAddressFormated, getFhirIdFromEntity, mergeLineAddress } from 'src/utils/fhir';
import { fToNow, parseShortFormat } from 'src/utils/formatTime';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import { wait } from 'src/utils/timers';
import { getParseColumn, UI_SCHEMA } from 'src/utils/utilities';
import agent from '../../../../api/agent';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { TableCustom } from '../../../../components/TableCustom';
import DispositionType from '../../../../components/workflow/common/components/Disposition';
import HiddenType from '../../../../components/workflow/common/components/Hidden';
import Input from '../../../../components/workflow/common/components/Input';
import SelectType from '../../../../components/workflow/common/components/Select';
import ReferralWorkflowComp from '../../../../components/workflow/Workflow';
import useAuth from '../../../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import {
  convertValueToValueSet,
  mapAssessmentsForms,
  mapCaseToCasesDisplay,
  mapCommunicationsToConsentFormDisplay,
  mapCommunicationsToNotesDisplay,
  mapContactAttemptsReferral,
  mapImmunizationsToDisplay,
} from '../../common/common-utils';
import NoteAddForm from '../../common/NoteAddForm';
import {
  STATUS_OPTION_IMMUNIZATION,
  TABLE_HEAD_ASSESSMENTS,
  TABLE_HEAD_CONSENT_FORM,
  TABLE_HEAD_CONTACT,
  TABLE_HEAD_IMMUNIZATIONS,
  TABLE_HEAD_NOTES,
} from '../../common/table-head';
import { translateTableHead } from '../../helpers/common';
import { DocumentList } from '../../patient/components/patientDocuments/DocumentList';
import StartAssessmentsButton from '../../patient/components/StartAssessmentsButton';
import ExternalLink from './ExternalLink';
import ReferralIdentifiers from './ReferralIdentifiers';

const getIdFromReference = (reference: string): string => reference.split('/')?.[1];

const ReferralDetails = memo(() => {
  const user = useAuth();

  const { themeStretch } = useSettings();

  const { enqueueSnackbar } = useSnackbar();

  const { confirmDialog } = useDialogStore();

  const [openImmunization, setOpenImmunization] = useState(false);

  const [openNote, setOpenNote] = useState(false);

  const { id: referralId = null } = useParams();

  const [patientId, setPatientId] = useState<string | null>(null);

  const [immunizationsQuery, setImmunizationsQuery] = useState({});
  const [communicationsQuery, setCommunicationsQuery] = useState({});
  const [documentReferencesQuery, setDocumentReferencesQuery] = useState({});
  const [carePlansQuery, setCarePlansQuery] = useState({});
  const [caseData, setCaseData] = useState<any | undefined | null>(null);
  const [caseDataByPatient, setCaseDataByPatient] = useState<any | undefined | null>(null);
  const [syncing, setSyncing] = useState<boolean>(false);
  const [fixAddressModalOpen, setFixAddressModalOpen] = useState(false);
  const [addAddressTabOpen, setAddAddressTabOpen] = useState(false);
  const [addressInCountyModalOpen, setAddressInCountyModalOpen] = useState(false);
  const [openInsuranceInfo, setOpenInsuranceInfo] = useState(false);
  const [openMissingProgressNotes, setOpenMissingProgressNotes] = useState(false);
  const [openMissingLabNotes, setOpenMissingLabNotes] = useState(false);
  const [openConsentForm, setOpenConsentForm] = useState(false);
  const [openContactClientForm, setOpenContactClientForm] = useState(false);
  const [openContactPCPForm, setOpenContactPCPForm] = useState(false);
  const [checklistData, setChecklistData] = useState<any>({});
  const [contactAttempt, setContactAttempt] = useState<ReferralContactAttemptResponse[]>([]);
  const [assessmentsForms, setAssessmentsForms] = useState<AssessmentsFormsResponse[]>([]);

  const [assessmentsLinks, setAssessmentsLinks] = useState<any[]>([]);

  const [workflowStage, setWorkflowStage] = useState(undefined);
  const [referralRecord, setReferralRecord] = useState<any>({});
  const [workflowInstance, setWorkflowInstance] = useState<any>({});
  const [allUsers, setAllUsers] = useState([]);
  const [disposition, setDisposition] = useState(false);
  const [reject, setReject] = useState(false);
  const [externalRefreshWorkFlow, setExternalRefreshWorkFlow] = useState(false);
  const [rejectCounty, setRejectCounty] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openCollapseReferralSummary, setOpenCollapseReferralSummary] = useState(true);
  const { i18n } = useLocales();
  const [initialWorkflow, setInitialWorkflow] = useState<any>(null);

  const lastContactClient =
    Array.isArray(contactAttempt) &&
    contactAttempt?.filter((e) => e.noteType === 'Contact Client').pop()
      ?.referralContactAttempContactOn;
  const countContactClient =
    Array.isArray(contactAttempt) &&
    contactAttempt?.filter((e) => e.noteType === 'Contact Client').length;
  const lastContactPCP =
    Array.isArray(contactAttempt) &&
    contactAttempt?.filter((e) => e.noteType === 'Document PCP').pop()
      ?.referralContactAttempContactOn;
  const countContactPCP =
    Array.isArray(contactAttempt) &&
    contactAttempt?.filter((e) => e.noteType === 'Document PCP').length;

  const buttonDataContactClient = `[${countContactClient}/5]
  ${
    !isEmpty(lastContactClient)
      ? `(Last on ${parseShortFormat(lastContactClient || '')} - 
  ${fToNow(lastContactClient || '')})`
      : ''
  }`;

  const buttonDataContactPCP = `[${countContactPCP}/5]
  ${
    !isEmpty(lastContactPCP)
      ? `(Last on ${parseShortFormat(lastContactPCP || '')} - 
  ${fToNow(lastContactPCP || '')})`
      : ''
  }`;

  const fetchAssessments = async (surveyId: any) => {
    const result: any = await referralService.referralApiClient.assessments(
      surveyId,
      'ServiceRequest'
    );
    setAssessmentsForms(mapAssessmentsForms(result));
  };

  const fetchAssessmentsLinks = async () => {
    const result: any = await getRegistries(undefined, 'crs-patient-assessments');
    const data = result?.data;
    const JsonObject = JSON.parse(data[0]?.keyValue);
    setAssessmentsLinks(JsonObject);
  };

  const fetchReferralRecord = async (id: any) => {
    const result: any = await referralService.referralApiClient.getOne(id);

    fetchAssessmentsLinks();
    setReferralRecord(result);
  };

  const fetchContactAttempt = async (fhirId: string) => {
    const result: any = await referralService.getContactAttempt(fhirId);
    setContactAttempt(result);
  };
  const getAllUsers = async () => {
    const result = await agent.User.getAllUsers();
    setAllUsers(result);
  };

  useEffect(() => {
    fetchAssessments(referralId);
    fetchReferralRecord(referralId);
    getAllUsers();
    fetchContactAttempt(referralId || '');
  }, [referralId]);

  /*
   ** Mock Data Workflow
   */
  // const REFERENCE_NAME = useMemo(() => referralRecord?.workflowScope, [referralRecord]);
  const REFERENCE_NAME = 'REFERRAL';

  // const INITIAL_WORKFLOW = useMemo(
  //   () => getWorkflowProperty(REFERENCE_NAME)?.code,
  //   [REFERENCE_NAME]
  // );

  useEffect(() => {
    fetchStageFromWorkflow();
  }, [referralId, referralRecord, REFERENCE_NAME]);

  //REFERRALS_WORKFLOW handler's
  const validateHomeItemHandler = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setFixAddressModalOpen(true);
    setChecklistData(checklistItem);
  };
  const validateAddressInCountyItemHandler = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setAddressInCountyModalOpen(true);
    setChecklistData(checklistItem);
  };
  const consentFormHandler = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setOpenConsentForm(true);
    setChecklistData(checklistItem);
  };
  const missingInsuranceHandler = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setOpenInsuranceInfo(true);
    setChecklistData(checklistItem);
  };
  const missingProgressNotesHandler = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setOpenMissingProgressNotes(true);
    setChecklistData(checklistItem);
  };
  const missingLabNotesHandler = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setOpenMissingLabNotes(true);
    setChecklistData(checklistItem);
  };
  const contactClientHandler = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setOpenContactClientForm(true);
    setChecklistData(checklistItem);
  };
  const contactPCPHandler = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setOpenContactPCPForm(true);
    setChecklistData(checklistItem);
  };

  //WORKFLOW common handler's
  const assignReferralItemHandler = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setChecklistData(checklistItem);
    const payloadForUpdateWorkflowinstance = {
      item: checklistItem,
      record: {
        ...referralRecord,
        ...workflowInstance,
      },
      value: more.selectedUser,
      attribute_name: 'ownedBy',
    };

    try {
      const response = await agent.Record.updateRecordByAttribute(payloadForUpdateWorkflowinstance);

      const payloadForSetItemCompleted = {
        item: checklistItem,
        record: {
          ...response,
        },
      };

      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      refreshChecklistHandler();
    } catch (error) {
      enqueueSnackbar('An error has occurred', { variant: 'error' });
      console.log('error on trying to assignReferralItemHandler');
    }
  };

  const fetchStageFromWorkflow = async () => {
    try {
      const result = await agent.Workflow.getInstance(referralId, REFERENCE_NAME);
      setWorkflowInstance(result);
      setWorkflowStage(result?.stage?.id);
      setInitialWorkflow(result?.workFlow?.code);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handlerDisposition = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setDisposition(true);
  };

  const handlerReject = async (open: any) => {
    setReject(open);
  };

  const referralWorkflowData = useMemo(
    () => ({
      id: 1,
      recordId: referralId,
      recordReference: REFERENCE_NAME,
      stage: {
        id: workflowStage,
      },
      referralRecord,
      workflowInstance,
    }),
    [workflowStage, workflowInstance, REFERENCE_NAME, referralId, referralRecord]
  );

  const WorkflowHandler = {
    ASSIGN_REFERRAL_ITEM: {
      type: 'payload',
      handler: assignReferralItemHandler,
      data: allUsers,
    },
    VALIDATE_HOME_ADDRESS_REFERRAL_ITEM: {
      type: 'payload',
      handler: validateHomeItemHandler,
      data: true,
    },
    VALIDATE_ADDRESS_IN_COUNTY_REFERRAL_ITEM: {
      type: 'payload',
      handler: validateAddressInCountyItemHandler,
      data: true,
    },
    MISSING_INSURANCE_INFO_REFERRAL_ITEM: {
      type: 'payload',
      handler: missingInsuranceHandler,
      data: true,
    },
    MISSING_PROGRESS_NOTES_REFERRAL_ITEM: {
      type: 'payload',
      handler: missingProgressNotesHandler,
      data: true,
    },
    MISSING_LAB_NOTES_REFERRAL_ITEM: {
      type: 'payload',
      handler: missingLabNotesHandler,
      data: true,
    },
    CONSENT_FORM_REFERRAL_ITEM: {
      type: 'payload',
      handler: consentFormHandler,
      data: true,
    },
    CONTACT_CLIENT_REFERRAL_ITEM: {
      type: 'payload',
      handler: contactClientHandler,
      data: buttonDataContactClient,
    },
    CONTACT_PCP_REFERRAL_ITEM: {
      type: 'payload',
      handler: contactPCPHandler,
      data: buttonDataContactPCP,
    },
    setDisposition: {
      type: 'confirm',
      handler: handlerDisposition,
      data: caseDataByPatient,
    },
    setReject: {
      type: 'confirm',
      handler: (open: boolean = true) => handlerReject(open),
      data: rejectCounty,
    },
    ASSIGN_OWNER_REMOTE_PATIENT_MONITORING_ITEM: {
      type: 'payload',
      handler: assignReferralItemHandler,
      data: allUsers,
    },
  };
  const WorkflowData = {
    disposition,
    reject,
  };
  const WorkflowComponentMapping: any = {
    Input,
    CustomSelect: CustomSelectType,
    Select: SelectType,
    Hidden: HiddenType,
    Disposition: DispositionType,
    Button: ButtonType,
    Link: LinkType,
    LinkModal: LinkModalType,
  };

  const [
    serviceRequest,
    {
      isError: isErrorServiceRequest,
      isLoading: isLoadingServiceRequest,
      isSuccess: isSuccessServiceRequest,
      refresh: refreshServiceRequest,
      update: updateServiceRequest,
    },
  ] = useServiceRequest(referralId, { map: ServiceRequestWrapper });
  const [
    patient,
    {
      isError: isErrorPatient,
      isLoading: isLoadingPatient,
      isSuccess: isSuccessPatient,
      update: updatePatient,
      refresh: refreshPatient,
    },
  ] = usePatient(patientId, {
    map: PatientWrapper,
  });

  const fhirPatientId = useMemo(
    () => referralRecord?.indexedFhirSubjectRefUri?.trim().slice('-36'),
    [referralRecord]
  );

  const [
    immunizations,
    {
      isLoading: isLoadingImmunizations,
      refresh: refreshImmunizations,
      create: createImmunization,
    },
  ] = useImmunizations({ filter: immunizationsQuery });

  const [communications, { refresh: refreshCommunications, create: createCommunication }] =
    useCommunications({
      filter: communicationsQuery,
      map: CommunicationWrapper,
    });

  const [
    documentReferences,
    {
      create: createDocumentReference,
      refresh: refreshDocumentReference,
      isFetching: isDocumentsLoading,
    },
  ] = useDocumentReferences({
    filter: documentReferencesQuery,
    map: DocumentReferenceWrapper,
  });

  const [carePlanCategory] = useValueSet('ph-service-request-category', { map: ValueSetWrapper });

  const { healthCareServices } = useHealthCareServicesByServiceRequests(
    referralId ? [referralId] : []
  );

  useEffect(() => {
    if (serviceRequest && isSuccessServiceRequest) {
      if (serviceRequest?.subject?.reference) {
        setPatientId(getIdFromReference(serviceRequest?.subject?.reference));
      }
      setCommunicationsQuery({ ...communicationsQuery, 'part-of': serviceRequest.id });
      setDocumentReferencesQuery({ ...documentReferencesQuery, related: serviceRequest.id });

      if (serviceRequest.hasCases()) {
        const careplanIds = serviceRequest.getCasesIds().join(',');
        setCarePlansQuery({ ...carePlansQuery, _id: careplanIds });
      }
    }
  }, [serviceRequest, isSuccessServiceRequest]);

  useEffect(() => {
    if (patientId) {
      setImmunizationsQuery({ ...immunizationsQuery, patient: patientId });
      getCaseListByPatient();
    }
  }, [patientId]);

  const handleCreateImmunization = async (data: any) => {
    const result = await createImmunization(data);
    refreshImmunizations();
    enqueueSnackbar('Immunization created successfully!');
    handleCloseAddImmunization();
  };

  const handleMarkItemCompleted = async () => {
    let result: any = {};
    const payload = {
      item: checklistData,
      record: {
        ...referralRecord,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payload);
    } catch (err) {
      console.log('error on handleInsuranceInfo markItemCompleted', err);
    } finally {
      setChecklistData({});
      setExternalRefreshWorkFlow(true);
    }

    setExternalRefreshWorkFlow(false);

    return result;
  };

  const handleCreateCommunication = async (data: any) => {
    let result: any = {};
    const payload = {
      item: checklistData,
      record: {
        ...referralRecord,
        ...workflowInstance,
      },
    };

    try {
      await agent.Checklist.markItemCompleted(payload);
      result = await handleCreateCommunicationFhir(data);
    } catch (err) {
      console.log('error on handleCreateCommunication', err);
    } finally {
      setOpenConsentForm(false);
      setChecklistData({});
      setExternalRefreshWorkFlow(true);
    }

    setExternalRefreshWorkFlow(false);

    return result;
  };

  const handleCreateCommunicationFhir = async (data: any) => {
    let result: any = {};

    try {
      result = await createCommunication(data);

      const setNote = result?.map((x1: Communication) => ({
        id: `${x1.resourceType}/${x1.id}`,
        text: x1?.payload?.[0]?.contentString || x1?.medium?.[0]?.coding?.[0]?.display,
      }));
      const note = serviceRequest?.note ?? [];
      const setNoteServiceRequest: Annotation[] = [...setNote, ...note];

      if (setNoteServiceRequest && serviceRequest) {
        serviceRequest.note = setNoteServiceRequest;
        handleUpdateReferral(serviceRequest);
      }

      handleCloseAddNote();
      refreshCommunications();
    } catch (err) {
      console.log('error on handleCreateCommunicationFhir', err);
    }

    return result;
  };

  const handleCreateContactForm = async (data: any) => {
    let resultContactAttempt: any = {};

    const workflowInstanceData =
      workflowInstance && workflowInstance.data && typeof workflowInstance.data === 'string'
        ? JSON.parse(workflowInstance.data)
        : workflowInstance && !workflowInstance?.data
        ? {}
        : workflowInstance?.data;

    const workflowInstanceDataSchema =
      workflowInstance &&
      workflowInstance.dataSchema &&
      typeof workflowInstance.dataSchema === 'string'
        ? JSON.parse(workflowInstance.dataSchema)
        : workflowInstance && !workflowInstance?.dataSchema
        ? {}
        : workflowInstance?.dataSchema;

    const payloadData =
      workflowInstanceData && Object.keys(workflowInstanceData).length > 0
        ? _.merge(workflowInstanceData, {
            scope: {
              checklistItem: {
                ...workflowInstanceData.checklistItem,
                [data?.type === 'Contact client item'
                  ? 'CONTACT_CLIENT_REFERRAL_ITEM'
                  : 'CONTACT_PCP_REFERRAL_ITEM']: {
                  validatedOn: new Date().toISOString(),
                  validatedBy: userEmail || '',
                  modifiedFields: {
                    ...data,
                  },
                },
              },
            },
          })
        : {
            scope: {
              checklistItem: {
                [data?.type === 'Contact client item'
                  ? 'CONTACT_CLIENT_REFERRAL_ITEM'
                  : 'CONTACT_PCP_REFERRAL_ITEM']: {
                  validatedOn: new Date().toISOString(),
                  validatedBy: userEmail || '',
                  modifiedFields: {
                    ...data,
                  },
                },
              },
            },
          };

    const CLIENT_TYPE =
      data?.type === 'Contact client item'
        ? UI_SCHEMA.CONTACT_CLIENT_REFERRAL_ITEM
        : UI_SCHEMA.CONTACT_PCP_REFERRAL_ITEM;

    const payloadDataSchema =
      workflowInstanceDataSchema && Object.keys(workflowInstanceDataSchema).length > 0
        ? _.merge(workflowInstanceDataSchema, {
            type: 'object',
            properties: {
              [checklistData.data]: CLIENT_TYPE,
            },
          })
        : {
            type: 'object',
            properties: {
              [checklistData.data]: CLIENT_TYPE,
            },
          };

    const payloadForUpdateWorkflowinstance = {
      referenceId: referralId,
      referenceName: REFERENCE_NAME,
      data: JSON.stringify(payloadData),
      dataSchema: JSON.stringify(payloadDataSchema),
    };

    const payloadForSetItemCompleted = {
      item: checklistData,
      record: {
        ...referralRecord,
        ...workflowInstance,
      },
    };

    try {
      resultContactAttempt = await referralService.createContactAttempt(data);
      if (!isEmpty(resultContactAttempt)) {
        const resultMark = await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
        const updateWorkflowInstance = await agent.Workflow.updateWorkflowInstance(
          payloadForUpdateWorkflowinstance
        );
        fetchContactAttempt(referralId || '');
      }
    } catch (err) {
      console.log('error on handleCreateContactForm markItemCompleted', err);
    } finally {
      setChecklistData({});
      setExternalRefreshWorkFlow(true);
      console.log('externalRefreshWorkFlow in contact form', externalRefreshWorkFlow);
    }

    setExternalRefreshWorkFlow(false);

    return resultContactAttempt;
  };

  const handleSyncReferral = async () => {
    setSyncing(true);
    try {
      await wait(1000);
      const { data } = await axiosInstance.post(`/crs/referral/${serviceRequest?.id}/sync`);
    } catch (error) {
      enqueueSnackbar('There was an error trying to sync referral. Please try again', {
        variant: 'error',
      });
      setSyncing(false);
      return;
    }
    enqueueSnackbar('Referral Synced Succesfully', {
      variant: 'success',
    });
    refreshServiceRequest();
    setSyncing(false);
  };

  const handleFixAddress = async (data: any) => {
    const address = patient?.address !== undefined ? [...patient?.address, data] : [data];

    try {
      await updatePatient(
        produce(patient!, (draft) => {
          draft.address = address;
        })
      );
      await refreshPatient();
      setAddAddressTabOpen(false);
    } catch (err) {
      console.log('error on handleFixAddress', err);
    }
  };

  const saveSelectedAddress = async (address: any) => {
    const workflowInstanceData =
      workflowInstance && workflowInstance.data && typeof workflowInstance.data === 'string'
        ? JSON.parse(workflowInstance.data)
        : workflowInstance && !workflowInstance?.data
        ? {}
        : workflowInstance?.data;

    const workflowInstanceDataSchema =
      workflowInstance &&
      workflowInstance.dataSchema &&
      typeof workflowInstance.dataSchema === 'string'
        ? JSON.parse(workflowInstance.dataSchema)
        : workflowInstance && !workflowInstance?.dataSchema
        ? {}
        : workflowInstance?.dataSchema;

    const payloadData =
      workflowInstanceData && Object.keys(workflowInstanceData).length > 0
        ? _.merge(workflowInstanceData, {
            scope: {
              checklistItem: {
                ...workflowInstanceData.checklistItem,
                VALIDATE_HOME_ADDRESS_REFERRAL_ITEM: {
                  validatedOn: new Date().toISOString(),
                  validatedBy: userEmail || '',
                  modifiedFields: {
                    ...address,
                  },
                },
              },
            },
          })
        : {
            scope: {
              checklistItem: {
                VALIDATE_HOME_ADDRESS_REFERRAL_ITEM: {
                  validatedOn: new Date().toISOString(),
                  validatedBy: userEmail || '',
                  modifiedFields: {
                    ...address,
                  },
                },
              },
            },
          };

    const VALIDATE_HOME_ADDRESS_REFERRAL_ITEM = UI_SCHEMA.VALIDATE_HOME_ADDRESS_REFERRAL_ITEM;

    const payloadDataSchema =
      workflowInstanceDataSchema && Object.keys(workflowInstanceDataSchema).length > 0
        ? _.merge(workflowInstanceDataSchema, {
            type: 'object',
            properties: {
              VALIDATE_HOME_ADDRESS_REFERRAL_ITEM,
            },
          })
        : {
            type: 'object',
            properties: {
              VALIDATE_HOME_ADDRESS_REFERRAL_ITEM,
            },
          };

    const payloadForUpdateWorkflowinstance = {
      referenceId: referralId,
      referenceName: REFERENCE_NAME,
      data: JSON.stringify(payloadData),
      dataSchema: JSON.stringify(payloadDataSchema),
    };

    const payloadForSetItemCompleted = {
      item: checklistData,
      record: {
        ...referralRecord,
        ...workflowInstance,
        isValidRequired: false,
      },
    };

    try {
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
      await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
    } catch (err) {
      console.log('error on saveSelectedAddress', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      fetchReferralRecord(referralId);
      setFixAddressModalOpen(false);
    }

    setExternalRefreshWorkFlow(false);
  };

  const saveCountyAddress = async (updatedIsInCountyPayload: any) => {
    const workflowInstanceData =
      workflowInstance && workflowInstance.data && typeof workflowInstance.data === 'string'
        ? JSON.parse(workflowInstance.data)
        : workflowInstance.data;

    const workflowInstanceDataSchema =
      workflowInstance &&
      workflowInstance.dataSchema &&
      typeof workflowInstance.dataSchema === 'string'
        ? JSON.parse(workflowInstance.dataSchema)
        : workflowInstance && !workflowInstance?.dataSchema
        ? {}
        : workflowInstance?.dataSchema;

    const payloadData =
      workflowInstanceData && Object.keys(workflowInstanceData).length > 0
        ? _.merge(workflowInstanceData, {
            scope: {
              checklistItem: {
                ...workflowInstanceData.checklistItem,
                VALIDATE_ADDRESS_IN_COUNTY_REFERRAL_ITEM: {
                  validatedOn: new Date().toISOString(),
                  validatedBy: userEmail || '',
                  modifiedFields: {
                    isInCounty: updatedIsInCountyPayload?.county === 'in' ? true : false,
                    Notes: updatedIsInCountyPayload?.notes,
                  },
                },
              },
            },
          })
        : {
            scope: {
              checklistItem: {
                VALIDATE_ADDRESS_IN_COUNTY_REFERRAL_ITEM: {
                  validatedOn: new Date().toISOString(),
                  validatedBy: userEmail || '',
                  modifiedFields: {
                    isInCounty: updatedIsInCountyPayload?.county === 'in' ? true : false,
                    Notes: updatedIsInCountyPayload?.notes,
                  },
                },
              },
            },
          };

    const VALIDATE_ADDRESS_IN_COUNTY_REFERRAL_ITEM =
      UI_SCHEMA.VALIDATE_ADDRESS_IN_COUNTY_REFERRAL_ITEM;

    const payloadDataSchema =
      workflowInstanceDataSchema && Object.keys(workflowInstanceDataSchema).length > 0
        ? _.merge(workflowInstanceDataSchema, {
            type: 'object',
            properties: {
              VALIDATE_ADDRESS_IN_COUNTY_REFERRAL_ITEM,
            },
          })
        : {
            type: 'object',
            properties: {
              VALIDATE_ADDRESS_IN_COUNTY_REFERRAL_ITEM,
            },
          };

    const payloadForUpdateWorkflowinstance = {
      referenceId: referralId,
      referenceName: REFERENCE_NAME,
      data: JSON.stringify(payloadData),
      dataSchema: JSON.stringify(payloadDataSchema),
    };

    const payloadForSetItemCompleted = {
      item: checklistData,
      record: {
        ...referralRecord,
        ...workflowInstance,
        isValidRequired: true,
      },
    };

    let resultMark: any;
    let updateWorkflowInstance: any;
    try {
      resultMark = await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
      updateWorkflowInstance = await agent.Workflow.updateWorkflowInstance(
        payloadForUpdateWorkflowinstance
      );
    } catch (err) {
      console.log('error on saveSelectedAddress', err);
    } finally {
      setExternalRefreshWorkFlow(true);
      fetchReferralRecord(referralId);
      setAddressInCountyModalOpen(false);
    }

    setExternalRefreshWorkFlow(false);

    if (resultMark && updateWorkflowInstance) {
      handleSetRejectCondition(updatedIsInCountyPayload?.county);
    }
  };

  const handleSetRejectCondition = async (data: any) => {
    if (data === 'out' || isEmpty(data)) {
      const confirms = await confirmDialog({
        title: 'Warning',
        description: (
          <>
            <Typography gutterBottom>¡The address is out the county!</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Do you want to reject this referral?
            </Typography>
          </>
        ),
      });

      if (confirms) {
        setRejectCounty(true);
        setReject(true);
      }
    }
  };

  const handleConfirmAddressInCounty = async (data: any) => {
    patient!.address! = data;
    await updatePatient(patient!);
    await refreshPatient();
  };

  const handleReAssingOwned = async (ownedSelected: string) => {
    try {
      const payload = {
        record: {
          ...workflowInstance,
          assigned: {
            selectedUser: ownedSelected,
          },
        },
      };

      await agent.Workflow.reAssignOwner(payload);
      fetchStageFromWorkflow();
    } catch (err) {
      console.log('error on handleReAssingOwned');
    }
  };

  const getPaginateQuery = () => {
    //@ts-ignore
    const { _id } = carePlansQuery;
    const paginationQuery: PaginateQuery = {};
    paginationQuery.filter = { fhirId: _id };
    return paginationQuery;
  };

  const getCaseList = async () => {
    const cases = await caseService.getAll(getPaginateQuery());
    if (cases) {
      setCaseData(cases);
    }
  };

  const getPaginateQueryByPatient = () => {
    const paginationQuery: PaginateQuery = {};
    paginationQuery.filter = {
      indexedSubjectFhirRefUri: `${process.env.REACT_APP_FHIR_API_BASE_URL}/Patient/${patientId}`,
      indexedStatus: 'active',
    };
    return paginationQuery;
  };

  const getCaseListByPatient = async () => {
    const cases = await caseService.getAll(getPaginateQueryByPatient());
    if (patientId && cases) {
      setCaseDataByPatient(cases);
    }
  };

  useEffect(() => {
    if (!isEmpty(carePlansQuery)) {
      getCaseList();
    }
  }, [carePlansQuery]);

  const dataCase = caseData?.data ? caseData.data.map((r: any) => mapCaseToCasesDisplay(r)) : [];

  const handleCreateCase = async (data: any) => {
    let carePlanFhirId: string = '';
    try {
      if (data?.fhirId) {
        caseService.update(data?.fhirId, {
          supportingInfo: [{ reference: `ServiceRequest/${serviceRequest?.id}` }],
        });
        carePlanFhirId = data?.fhirId;
      } else if (data === 'new') {
        const categoryValueSet = convertValueToValueSet('mint_referral', carePlanCategory);
        const payload = {
          patientId: getFhirIdFromEntity(referralRecord?.indexedFhirSubjectRefUri) || '',
          intent: 'plan' as CaseIntent,
          status: 'active' as CaseStatus,
          assignedTo: data?.referralRecord?.workflowOwnedBy,
          supportingInfo: [{ reference: `ServiceRequest/${serviceRequest?.id}` }],
          scope: [
            {
              ...(categoryValueSet && { coding: [categoryValueSet] }),
              text: categoryValueSet?.display,
            },
          ],
        };
        const result = await caseService.create(payload);
        carePlanFhirId = result?.fhirId ?? '';
      }

      if (!carePlanFhirId) return;

      const result = referralService.update(serviceRequest?.id!, {
        supportingInfo: [{ reference: `CarePlan/${carePlanFhirId}` }],
      });
    } catch (error) {
      enqueueSnackbar('An error has occurred.', { variant: 'error' });
    }
  };

  const handleCloseInsuranceInfo = () => {
    setOpenInsuranceInfo(false);
  };

  const handleCloseMissingProgressNotes = () => {
    setOpenMissingProgressNotes(false);
  };

  const handleCloseMissingLabNotes = () => {
    setOpenMissingLabNotes(false);
  };

  const handleCloseConsentForm = () => {
    setOpenConsentForm(false);
  };

  const handleCloseContactClientForm = () => {
    setOpenContactClientForm(false);
  };

  const handleCloseContactPCPForm = () => {
    setOpenContactPCPForm(false);
  };

  const handleOpenAddImmnization = () => {
    setOpenImmunization((prev) => !prev);
  };

  const handleCloseAddImmunization = () => {
    setOpenImmunization(false);
  };

  const handleOpenNote = () => {
    setOpenNote((prev) => !prev);
  };

  const handleCloseAddNote = () => {
    setOpenNote(false);
  };

  const handleWorkflowDisposition = (data: any) => {
    if (!serviceRequest?.id) return;
    handleCreateCase(data);
    createProgramsByServiceRequest(serviceRequest.id);
  };

  const userEmail = user?.getCurrentUser?.()?.email;

  const handleOpenFromIsCounty = () => setFixAddressModalOpen(true);

  const handleUpdateReferral = async (data: ServiceRequest) => {
    const id = data?.id;
    const isEditing = Boolean(id);

    let result: any = {};

    if (isEditing) {
      result = await updateServiceRequest(
        produce(serviceRequest!, (draft) => {
          draft.supportingInfo = data.supportingInfo;
          draft.note = data.note;
        })
      );
    }

    refreshServiceRequest();

    return result;
  };

  const onUpdateIdentifiers = useCallback(
    async (newIdentifiers: Identifier[]) => {
      await updateServiceRequest(
        produce(serviceRequest!, (draft) => {
          draft.identifier = newIdentifiers;
        })
      );
    },
    [serviceRequest]
  );

  const handlerComplementaryInfo = async (store: any) => {
    if (store.VALIDATE_HOME_ADDRESS_REFERRAL_ITEM) {
      const originalAddress = mergeLineAddress(
        store.VALIDATE_HOME_ADDRESS_REFERRAL_ITEM?.modifiedFields
      );
      store.VALIDATE_HOME_ADDRESS_REFERRAL_ITEM.modifiedFields = { ...originalAddress };
    }

    const payloadForUpdateWorkflowinstance = {
      referenceId: workflowInstance?.referenceId,
      referenceName: workflowInstance?.referenceName,
      data: JSON.stringify({
        scope: {
          checklistItem: {
            ...store,
          },
        },
      }),
    };

    try {
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error on handleComplementaryInfo', err);
    } finally {
      setExternalRefreshWorkFlow(true);
    }

    setExternalRefreshWorkFlow(false);
  };

  const defaultStructure = useMemo(() => {
    const checklistItem = getParseColumn(workflowInstance, 'data')?.scope?.checklistItem;

    const addressFormated = getAddressFormated(
      checklistItem?.VALIDATE_HOME_ADDRESS_REFERRAL_ITEM?.modifiedFields
    );

    return {
      ...(checklistItem?.VALIDATE_ADDRESS_IN_COUNTY_REFERRAL_ITEM?.validatedOn && {
        VALIDATE_ADDRESS_IN_COUNTY_REFERRAL_ITEM: {
          validatedOn: moment
            .utc(new Date(checklistItem?.VALIDATE_ADDRESS_IN_COUNTY_REFERRAL_ITEM?.validatedOn))
            .format('MM/DD/YYYY'),
        },
      }),
      ...(checklistItem?.VALIDATE_HOME_ADDRESS_REFERRAL_ITEM && {
        VALIDATE_HOME_ADDRESS_REFERRAL_ITEM: {
          ...(checklistItem?.VALIDATE_HOME_ADDRESS_REFERRAL_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(new Date(checklistItem?.VALIDATE_HOME_ADDRESS_REFERRAL_ITEM?.validatedOn))
              .format('MM/DD/YYYY'),
          }),
          modifiedFields: {
            ...(checklistItem?.VALIDATE_HOME_ADDRESS_REFERRAL_ITEM?.modifiedFields && {
              ...addressFormated,
            }),
          },
        },
      }),
      ...(checklistItem?.CONTACT_CLIENT_REFERRAL_ITEM && {
        CONTACT_CLIENT_REFERRAL_ITEM: {
          ...(checklistItem?.CONTACT_CLIENT_REFERRAL_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(new Date(checklistItem?.CONTACT_CLIENT_REFERRAL_ITEM?.validatedOn))
              .format('MM/DD/YYYY'),
          }),
          modifiedFields: {
            ...(checklistItem?.CONTACT_CLIENT_REFERRAL_ITEM?.modifiedFields?.contactOn && {
              contactOn: moment
                .utc(
                  new Date(checklistItem?.CONTACT_CLIENT_REFERRAL_ITEM?.modifiedFields?.contactOn)
                )
                .format('MM/DD/YYYY'),
            }),
            ...(checklistItem?.CONTACT_CLIENT_REFERRAL_ITEM?.modifiedFields?.nextContactOn && {
              nextContactOn: moment
                .utc(
                  new Date(
                    checklistItem?.CONTACT_CLIENT_REFERRAL_ITEM?.modifiedFields?.nextContactOn
                  )
                )
                .format('MM/DD/YYYY'),
            }),
          },
        },
      }),
      ...(checklistItem?.CONTACT_PCP_REFERRAL_ITEM && {
        CONTACT_PCP_REFERRAL_ITEM: {
          ...(checklistItem?.CONTACT_PCP_REFERRAL_ITEM?.validatedOn && {
            validatedOn: moment
              .utc(new Date(checklistItem?.CONTACT_PCP_REFERRAL_ITEM?.validatedOn))
              .format('MM/DD/YYYY'),
          }),
          modifiedFields: {
            ...(checklistItem?.CONTACT_PCP_REFERRAL_ITEM?.modifiedFields?.contactOn && {
              contactOn: moment
                .utc(new Date(checklistItem?.CONTACT_PCP_REFERRAL_ITEM?.modifiedFields?.contactOn))
                .format('MM/DD/YYYY'),
            }),
            ...(checklistItem?.CONTACT_PCP_REFERRAL_ITEM?.modifiedFields?.nextContactOn && {
              nextContactOn: moment
                .utc(
                  new Date(checklistItem?.CONTACT_PCP_REFERRAL_ITEM?.modifiedFields?.nextContactOn)
                )
                .format('MM/DD/YYYY'),
            }),
          },
        },
      }),
    };
  }, [workflowInstance]);

  const referralPermissions = useMemo(
    () => ({
      isAllowedToEdit: checkAclValidation({ acls: [crsAcls.CRS.REFERRAL.EDIT] }),
    }),
    []
  );

  return (
    <Page title={i18n('referral.details.title', 'crs')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          title={i18n('referral.details.title', 'crs')}
          heading=""
          links={[
            { name: `${i18n('admin.list.dashboard')}`, href: PATH_DASHBOARD.root },
            { name: `${i18n('breadcrumbs.title', 'crs')}` },
            { name: `${i18n('referral.list.title', 'crs')}`, href: PATH_DASHBOARD.crs.referral },
            { name: `${i18n('referral.details.title', 'crs')}` },
          ]}
          action={
            <Grid container direction={'row'}>
              <Grid item xs={6}>
                <ExternalLink externalLinkKeysForData={['deviceManagementLink']} />
              </Grid>

              {REFERENCE_NAME === 'REFERRAL' && (
                <Grid item xs={6}>
                  <LoadingButton
                    sx={{ float: 'right', height: '100%' }}
                    variant="contained"
                    disabled={syncing || !serviceRequest?.id}
                    onClick={handleSyncReferral}
                    loading={syncing || !serviceRequest?.id}
                  >
                    {i18n('referral.details.syncReferral', 'crs')}
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          }
        />
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <ReferralWorkflowComp
              data={referralWorkflowData}
              refreshRecord={fetchStageFromWorkflow}
              initialWorkflow={initialWorkflow}
              workflowHandler={WorkflowHandler}
              workflowData={WorkflowData}
              componentMapping={WorkflowComponentMapping}
              refreshWorkFlowExternal={externalRefreshWorkFlow}
              onWorkflowDisposition={handleWorkflowDisposition}
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Typography sx={{ mt: 1.5 }} variant="overline">
                collapse all
              </Typography>
              <IconButton onClick={() => setOpenCollapse(!openCollapse)}>
                <Iconify
                  icon={openCollapse ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ReferralSummary
              referralRecord={referralRecord}
              serviceRequest={serviceRequest}
              title={'Referral Summary'}
              workflowInstance={workflowInstance}
              users={allUsers}
              onOwnedAssign={handleReAssingOwned}
              fhirPatient={fhirPatientId}
              handlers={{
                insuranceHandler: setOpenInsuranceInfo,
                progressNotesHandler: setOpenMissingProgressNotes,
                labNotesHandler: setOpenMissingLabNotes,
                consentHandler: setOpenConsentForm,
              }}
              referralWorkflowData={referralWorkflowData}
              openCollapseExternal={openCollapseReferralSummary}
              children={null}
              healthCareServices={healthCareServices}
              updateServiceRequest={updateServiceRequest}
              isAllowedToEdit={referralPermissions.isAllowedToEdit}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ExpandableCard
              title={'Referral Complementary Info'}
              openCollapseExternal={openCollapse}
            >
              <ComplementaryInfo
                dataSchema={getParseColumn(workflowInstance, 'dataSchema')}
                data={getParseColumn(workflowInstance, 'data')}
                defaultStructure={defaultStructure}
                checklistItemModified={defaultStructure?.VALIDATE_HOME_ADDRESS_ITEM}
                handleComplementaryInfo={handlerComplementaryInfo}
                isAllowedToEdit={referralPermissions.isAllowedToEdit}
              />
            </ExpandableCard>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            {patient && (
              <PatientSummary
                title={'Patient Information'}
                patient={patient}
                openCollapseExternal={openCollapse}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ReferralIdentifiers
              serviceRequest={serviceRequest}
              isOpen={openCollapse}
              onUpdateIdentifiers={onUpdateIdentifiers}
            />
          </Grid>

          <Grid item xs={12}>
            <ExpandableCard
              title={i18n('referral.details.secondaryReferrals.title', 'crs')}
              openCollapseExternal={openCollapse}
            >
              <ReferralHistory serviceRequest={serviceRequest} patient={patient} />
            </ExpandableCard>
          </Grid>

          <Grid item xs={12}>
            <ExpandableCard
              title={i18n('referral.details.inmmunizations.title', 'crs')}
              openCollapseExternal={openCollapse}
            >
              <TableCustom
                data={mapImmunizationsToDisplay(immunizations)}
                tableHead={translateTableHead(TABLE_HEAD_IMMUNIZATIONS, 'crs')}
                handleOpen={handleOpenAddImmnization}
                titleButton={i18n('referral.details.inmmunizations.button', 'crs')}
                statusList={STATUS_OPTION_IMMUNIZATION}
              />
            </ExpandableCard>
          </Grid>

          {/* <Grid item xs={12}>
            <Card>
              <Typography variant="subtitle1" sx={{ m: 2 }}>
                Cases
              </Typography>
              <SummaryTable header={TABLE_HEAD_CASES} data={dataCase} />
            </Card>
          </Grid> */}

          <Grid item xs={12}>
            <ExpandableCard
              title={i18n('referral.details.contactAttempts.title', 'crs')}
              openCollapseExternal={openCollapse}
            >
              <TableCustom
                data={Array.isArray(contactAttempt) && mapContactAttemptsReferral(contactAttempt)}
                tableHead={translateTableHead(TABLE_HEAD_CONTACT, 'crs')}
                childrenButtons={
                  <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
                    {REFERENCE_NAME === 'REFERRAL' && (
                      <>
                        <Button onClick={() => setOpenContactClientForm(true)}>
                          Contact Client
                        </Button>
                        <Button onClick={() => setOpenContactPCPForm(true)}>Contact PCP</Button>
                      </>
                    )}
                  </Grid>
                }
              />
            </ExpandableCard>
          </Grid>

          <Grid item xs={12}>
            <ExpandableCard
              title={i18n('referral.details.assessmentsForm.title', 'crs')}
              openCollapseExternal={openCollapse}
            >
              <TableCustom
                data={Array.isArray(assessmentsForms) && assessmentsForms}
                tableHead={translateTableHead(TABLE_HEAD_ASSESSMENTS, 'crs')}
                childrenButtons={
                  <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
                    {checkAclValidation({
                      acls: [crsAcls.CRS.PATIENT.ASSESSMENTS.START],
                    }) && (
                      <StartAssessmentsButton
                        keyRegistry="crs-patient-assessments"
                        patientId={patient?.id ?? ''}
                        additionalQueryParams={`&referralUUID=${referralId}`}
                      />
                    )}
                  </Grid>
                }
              />
            </ExpandableCard>
          </Grid>

          <Grid item xs={12}>
            <ExpandableCard title={i18n('Consent Form', 'crs')} openCollapseExternal={openCollapse}>
              <TableCustom
                data={mapCommunicationsToConsentFormDisplay(communications, 'consent_form')}
                tableHead={translateTableHead(TABLE_HEAD_CONSENT_FORM, 'crs')}
                statusList={STATUS_OPTION_IMMUNIZATION}
              />
            </ExpandableCard>
          </Grid>

          <Grid item xs={12}>
            <ExpandableCard
              title={i18n('referral.details.notesDetails.title', 'crs')}
              openCollapseExternal={openCollapse}
            >
              <TableCustom
                data={mapCommunicationsToNotesDisplay(communications, [
                  'notes_referral',
                  'mint_referral_notes',
                  'notes_member_engagement',
                ])}
                tableHead={translateTableHead(TABLE_HEAD_NOTES, 'crs')}
                handleOpen={handleOpenNote}
                titleButton={i18n('referral.details.notesDetails.button', 'crs')}
              />
            </ExpandableCard>
          </Grid>

          <Grid item xs={12}>
            <ExpandableCard title={i18n('patients.details.attachments.title', 'crs')}>
              <Card style={{ boxShadow: 'none', position: 'static' }}>
                <DocumentList
                  patient={patient}
                  documentReferences={
                    isDocumentsLoading || !(documentReferencesQuery as any)?.related
                      ? []
                      : documentReferences
                  }
                  resource={serviceRequest}
                  refreshDocumentReference={refreshDocumentReference}
                  openCollapseExternal={openCollapse}
                  handleUpdateResource={handleUpdateReferral}
                />
              </Card>
            </ExpandableCard>
          </Grid>
        </Grid>

        <FixAddressDialog
          isOpen={fixAddressModalOpen}
          handleClose={() => setFixAddressModalOpen(false)}
          handleSave={handleFixAddress}
          saveSelectedAddress={saveSelectedAddress}
          addressesList={patient?.address}
          addAddressTab={addAddressTabOpen}
          handleAddAddressOpen={setAddAddressTabOpen}
        />

        <AddressInCountyDialog
          isOpen={addressInCountyModalOpen}
          handleClose={() => setAddressInCountyModalOpen(false)}
          handleSave={handleConfirmAddressInCounty}
          addressSaved={workflowInstance?.data}
          saveCountyAddress={saveCountyAddress}
          addressList={patient?.address}
          handleOpenFromIsCounty={handleOpenFromIsCounty}
        />

        <ImmunizationAddForm
          open={openImmunization}
          onCancel={handleCloseAddImmunization}
          handleCreateImmunization={handleCreateImmunization}
          patient={patient}
        />

        <NoteAddForm
          open={openNote}
          patient={patient}
          onCancel={handleCloseAddNote}
          resource={[serviceRequest]}
          handleCreate={handleCreateCommunicationFhir}
          typeNote={REFERENCE_NAME === 'REFERRAL' ? 'notes_referral' : 'notes_member_engagement'}
        />

        <InsuranceInfo
          patient={patient}
          serviceRequest={serviceRequest}
          open={openInsuranceInfo}
          onCancel={handleCloseInsuranceInfo}
          workflowReferral={true}
          handleInsuranceInfo={handleMarkItemCompleted}
          handleUpdateResource={handleUpdateReferral}
        />

        <MissingNotes
          option="Link Progress Notes"
          patient={patient}
          serviceRequest={serviceRequest}
          open={openMissingProgressNotes}
          onCancel={handleCloseMissingProgressNotes}
          workflowReferral={true}
          handleNotes={handleMarkItemCompleted}
          handleUpdateResource={handleUpdateReferral}
        />

        <MissingNotes
          option="Link Lab Notes"
          patient={patient}
          serviceRequest={serviceRequest}
          open={openMissingLabNotes}
          onCancel={handleCloseMissingLabNotes}
          workflowReferral={true}
          handleNotes={handleMarkItemCompleted}
          handleUpdateResource={handleUpdateReferral}
        />

        <ConsentForm
          patient={patient}
          communication={communications}
          serviceRequest={serviceRequest}
          handleCreateCommunication={handleCreateCommunication}
          open={openConsentForm}
          onCancel={handleCloseConsentForm}
        />

        <ContactForm
          option={i18n('referral.details.contactAttempts.contactClientTitle', 'crs')}
          lastRecord={lastContactClient}
          countRecord={countContactClient}
          referral={referralRecord}
          open={openContactClientForm}
          onCancel={handleCloseContactClientForm}
          handleCreateContactForm={handleCreateContactForm}
          assessmentsLinks={assessmentsLinks}
          patientId={patientId}
        />

        <ContactForm
          option={i18n('referral.details.contactAttempts.documentPcp', 'crs')}
          lastRecord={lastContactPCP}
          countRecord={countContactPCP}
          referral={referralRecord}
          open={openContactPCPForm}
          onCancel={handleCloseContactPCPForm}
          handleCreateContactForm={handleCreateContactForm}
        />
      </Container>
    </Page>
  );
});

export default ReferralDetails;
